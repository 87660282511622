import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14320997"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "myAccountContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailPassword = _resolveComponent("EmailPassword")!
  const _component_PhoneNumber = _resolveComponent("PhoneNumber")!
  const _component_master_layout = _resolveComponent("master-layout")!

  return (_openBlock(), _createBlock(_component_master_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_EmailPassword, { id: "emailpasswordContainer" }),
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('EDIT_PHONE')), 1 /* TEXT */),
        _createVNode(_component_PhoneNumber, { id: "phoneNumberContainer" })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}